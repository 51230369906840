import data from './data'

export default () => {
  const typeOptions = [
    { label: '홈 탭 상단 위젯', value: 'HOME_SIMPLE' },
    { label: '최신 등록 상품', value: 'RECENT_PRODUCT' },
    { label: '추천 캠페인', value: 'RECOMMEND_CAMPAIGN' },
    { label: '상단고정 캠페인', value: 'FIXED_CAMPAIGN' },
  ]
  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]
  return {
    typeOptions,
    stateOptions,
    ...data.widget,
  }
}
