<template>
  <BRow>
    <BCol>
      <BFormGroup :label="label">
        <slot />
      </BFormGroup>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  props: {
    label: {
      type: String,
    },
  },
  setup() {

  },
})
</script>
