import {
  computed,
} from '@vue/composition-api'
import data from './data'
import actions from './actions'

export default () => {
  const fields = [
    { key: 'idx' },
    { key: 'thumbnail_img_url', label: 'img' },
    { key: 'tagby_product_no' },
    { key: 'name' },
  ]
  const turnOnSidebar = () => {
    if (data.recentProduct.items.value?.length > 0) {
      const idxList = data.recentProduct.items.value.map(item => item.idx)
      const textValue = idxList.join('\n')
      data.recentProduct.text.value = textValue
    }
    data.recentProduct.visible.value = true
  }
  const turnOffSidebar = () => {
    data.recentProduct.visible.value = false
  }
  const isValid = computed(() => Boolean(data.recentProduct.text.value))
  const getIdxList = () => {
    const textValue = data.recentProduct.text.value
    if (textValue == null) throw Error()
    const idxList = textValue.split('\n').filter(idx => Boolean(idx)).map(idx => parseInt(idx, 10))
    return idxList
  }
  const searchProductList = async () => {
    const idxList = getIdxList()
    const response = await actions.searchProductList({
      idx_list: idxList,
    })
    const productList = response.data.data
    return productList
  }
  const updateProductList = async () => {
    data.recentProduct.errorMessage.value = null
    data.recentProduct.isSaving.value = true
    try {
      const idxList = getIdxList()
      const productList = await searchProductList()
      const productIdxList = productList.map(p => p.idx)
      const notExistIdxList = idxList.filter(idx => !productIdxList.includes(idx))
      if (notExistIdxList.length > 0) {
        data.recentProduct.errorMessage.value = `${notExistIdxList}가 존재하지 않습니다`
      } else {
        data.recentProduct.items.value = productList
        turnOffSidebar()
      }
    } catch (e) {
      console.log(e)
    } finally {
      data.recentProduct.isSaving.value = false
    }
  }
  return {
    fields,
    turnOnSidebar,
    turnOffSidebar,
    updateProductList,
    isValid,
    ...data.recentProduct,
  }
}
