import data from './data'
import actions from './actions'
import useToase from '@/utils/toast'
import router from '@/router'

export default () => {
  const { makeToast } = useToase()
  const createHomeSimple = () => {
    data.widget.isSaving.value = true
    actions.createHomeSimple({
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
      },
      content_info: {
        title: data.homeSimple.title.value,
        text: data.homeSimple.text.value,
        img_url: data.homeSimple.imgUrl.value,
      },
    }).then(response => {
      makeToast('primary', '위젯 생성에 성공했습니다')
      router.push({ name: 'app-manage-widget-detail', params: { idx: response.data.data } })
    }).catch(() => {
      makeToast('danger', '위젯 생성에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const createRecentProduct = () => {
    data.widget.isSaving.value = true
    const idxList = data.recentProduct.items.value?.map(item => item.idx) ?? []
    actions.createRecentProduct({
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
        start_at: data.widget.startAt.value,
        end_at: data.widget.endAt.value,
      },
      content_info: {
        idx_list: idxList,
      },
    }).then(response => {
      makeToast('primary', '위젯 생성에 성공했습니다')
      router.push({ name: 'app-manage-widget-detail', params: { idx: response.data.data } })
    }).catch(() => {
      makeToast('danger', '위젯 생성에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const createRecommendCampaign = () => {
    data.widget.isSaving.value = true
    const idxList = data.recommendCampaign.items.value?.map(item => item.idx) ?? []
    actions.createRecommendCampaign({
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
      },
      content_info: {
        idx_list: idxList,
      },
    }).then(response => {
      makeToast('primary', '위젯 생성에 성공했습니다')
      router.push({ name: 'app-manage-widget-detail', params: { idx: response.data.data } })
    }).catch(() => {
      makeToast('danger', '위젯 생성에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const createFixedCampaign = () => {
    data.widget.isSaving.value = true
    const idxList = data.recommendCampaign.items.value?.map(item => item.idx) ?? []
    actions.createFixedCampaign({
      widget_info: {
        state: data.widget.state.value,
        name: data.widget.name.value,
      },
      content_info: {
        idx_list: idxList,
      },
    }).then(response => {
      makeToast('primary', '위젯 생성에 성공했습니다')
      router.push({ name: 'app-manage-widget-detail', params: { idx: response.data.data } })
    }).catch(() => {
      makeToast('danger', '위젯 생성에 실패했습니다')
    }).finally(() => {
      data.widget.isSaving.value = false
    })
  }
  const create = () => {
    if (data.widget.type.value === data.const.TYPE.HOME_SIMPLE) {
      createHomeSimple()
    } else if (data.widget.type.value === data.const.TYPE.RECENT_PRODUCT) {
      createRecentProduct()
    } else if (data.widget.type.value === data.const.TYPE.RECOMMEND_CAMPAIGN) {
      createRecommendCampaign()
    } else if (data.widget.type.value === data.const.TYPE.FIXED_CAMPAIGN) {
      createFixedCampaign()
    }
  }

  return {
    create,
    isSaving: data.widget.isSaving,
  }
}
