import {
  ref,
} from '@vue/composition-api'

const type = ref()
const name = ref()
const state = ref()
const startAt = ref()
const endAt = ref()

const homeSimpleTitle = ref('')
const homeSimpleText = ref('')
const homeSimpleImgUrl = ref('')

const recentProductItems = ref()
const recentProductText = ref()

const recommendCampaignItems = ref()
const recommendCampaignText = ref()

export default {
  const: {
    TYPE: {
      HOME_SIMPLE: 'HOME_SIMPLE',
      RECENT_PRODUCT: 'RECENT_PRODUCT',
      RECOMMEND_CAMPAIGN: 'RECOMMEND_CAMPAIGN',
      FIXED_CAMPAIGN: 'FIXED_CAMPAIGN',
    },
  },
  widget: {
    type,
    name,
    state,
    startAt,
    endAt,
    isSaving: ref(false),
  },
  homeSimple: {
    title: homeSimpleTitle,
    text: homeSimpleText,
    imgUrl: homeSimpleImgUrl,
    isSaving: ref(false),
  },
  recentProduct: {
    items: recentProductItems,
    text: recentProductText,
    visible: ref(false),
    isSaving: ref(false),
    errorMessage: ref(),
  },
  recommendCampaign: {
    items: recommendCampaignItems,
    text: recommendCampaignText,
    visible: ref(false),
    isSaving: ref(false),
    errorMessage: ref(),
  },
}
