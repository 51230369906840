import {
  ref,
} from '@vue/composition-api'
import data from './data'
import uploadFile from '@/utils/uploadFile'

export default () => {
  const file = ref()
  async function inputFile(value) {
    const fileInfo = await uploadFile(value, 'app_widget_info')
    data.homeSimple.imgUrl.value = fileInfo.file_url
  }
  return {
    file,
    inputFile,
    ...data.homeSimple,
  }
}
