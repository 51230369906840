<template>
  <TagbyDetailLayout>
    <template #content>
      <WidgetInfo />
      <ContentInfo />
    </template>

    <template #side>
      <ActionCard />
    </template>

    <template #hidden>
      <UpdateRecentProductSidebar />
      <UpdateRecommendCampaignSidebar />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import WidgetInfo from './blocks/WidgetInfo'
import ContentInfo from './blocks/ContentInfo'
import ActionCard from './blocks/ActionCard'
import UpdateRecentProductSidebar from './blocks/UpdateRecentProductSidebar'
import UpdateRecommendCampaignSidebar from './blocks/UpdateRecommendCampaignSidebar'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    WidgetInfo,
    ContentInfo,
    ActionCard,
    UpdateRecentProductSidebar,
    UpdateRecommendCampaignSidebar,
  },
  setup() {

  },
})
</script>
