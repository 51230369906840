<template>
  <TagbySidebar
    :visible="visible"
    :width="width"
    :title="title"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
  >
    <template #content>
      <slot />
      <TagbySidebarButtons
        :isSaving="isSaving"
        :isValid="isValid"
        :okText="okText"
        :cancelText="cancelText"
        @ok="$emit('ok')"
        @cancel="$emit('cancel')"
      />
    </template>
  </TagbySidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbySidebar from './TagbySidebar.vue'
import TagbySidebarButtons from './TagbySidebarButtons.vue'

export default defineComponent({
  components: {
    TagbySidebar,
    TagbySidebarButtons,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '30%',
    },
    title: {
      type: String,
      default: null,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: '확인',
    },
    cancelText: {
      type: String,
      default: '취소',
    },
  },
  emits: [
    'hidden',
    'shown',
    'ok',
    'cancel',
  ],
  setup() {

  },
})
</script>
