<template>
  <BRow>
    <BCol>
      <BButton
        variant="outline-primary"
        class="mr-1"
        :disabled="!isValid"
        @click="$emit('ok')"
      >
        <BSpinner
          v-if="isSaving"
          small
        />
        {{ okText }}
      </BButton>
      <BButton
        variant="outline-secondary"
        @click="$emit('cancel')"
      >
        {{ cancelText }}
      </BButton>
    </BCol>
  </BRow>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  props: {
    isSaving: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
    okText: {
      type: String,
      default: '확인',
    },
    cancelText: {
      type: String,
      default: '취소',
    },
  },
}
</script>
