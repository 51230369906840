<template>
  <BRow>
    <BCol cols="6">
      <BImg
        :src="require('./home_simple_ex.png')"
        fluid
        rounded
      />
    </BCol>
    <BCol
      cols="6"
      class="text-info"
    >
      <div>
        - 홈 탭의 상단에 위치한 위젯
      </div>
      <div>
        - 링크 텍스트를 누르면 추천 캠페인 페이지로 연결됨
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BImg,
  },
  setup() {

  },
})
</script>
