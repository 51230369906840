<template>
  <BRow>
    <BCol
      cols="12"
      class="text-info"
    >
      <div>
        - 추천 캠페인 페이지
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BImg,
  },
  setup() {

  },
})
</script>
