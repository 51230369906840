<template>
  <TagbySidebarWithButton
    title="캠페인 목록 수정"
    :visible="visible"
    :isValid="isValid"
    :isSaving="isSaving"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
    @ok="updateCampaignList"
  >
    <TagbyInputRow label="IDX_LIST">
      {{ errorMessage }}
      <BFormTextarea
        v-model="text"
        rows="10"
        placeholder="idx 목록을 enter로 구분해서 적어주세요"
        :state="errorMessage == null ? null : false"
      />
      <BFormInvalidFeedback>
        {{ errorMessage }}
      </BFormInvalidFeedback>
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormTextarea,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import useRecommendCampaign from '../../useRecommendCampaign'
import TagbyInputRow from '@/components/TagbyInputRow.vue'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  setup() {
    return {
      ...useRecommendCampaign(),
    }
  },
})
</script>
