<template>
  <div>
    <BButton
      variant="outline-secondary mb-1"
      @click="turnOnSidebar"
    >
      아이템 목록 수정
    </BButton>
    <BTable
      :fields="fields"
      :items="items"
    >
      <template #cell(idx)="data">
        <BLink
          :to="{name: 'commerce-product-detail', params: { idx: data.value } }"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(thumbnail_img_url)="data">
        <BImg
          :src="data.value"
          width="60"
          height="60"
          rounded
        />
      </template>
    </BTable>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BButton,
  BTable,
  BLink,
  BImg,
} from 'bootstrap-vue'
import useRecentProduct from '../../useRecentProduct'

export default defineComponent({
  components: {
    BButton,
    BTable,
    BLink,
    BImg,
  },
  setup() {
    return {
      ...useRecentProduct(),
    }
  },
})
</script>
