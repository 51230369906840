<template>
  <b-sidebar
    bg-variant="white"
    right
    backdrop
    shadow
    no-header
    :visible="visible"
    :width="width"
    @shown="$emit('shown')"
    @hidden="$emit('hidden')"
  >
    <template #default="{hide}">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          <slot name="title">
            {{ title }}
          </slot>
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <div class="p-1">
        <slot />
        <slot name="content" />
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BSidebar,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BSidebar,
  },
  emits: [
    'hidden',
    'shown',
  ],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '30%',
    },
    title: {
      type: String,
      default: null,
    },
  },
  setup() {

  },
})
</script>
