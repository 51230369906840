import axiosFrame from '@/utils/axiosFrame'

export default {
  searchProductList: axiosFrame('post', '/manage/app-widget/search-product-list/'),
  searchCampaignList: axiosFrame('post', '/manage/app-widget/search-campaign-list/'),
  createHomeSimple: axiosFrame('post', '/manage/app-widget/create-home-simple/'),
  createRecentProduct: axiosFrame('post', '/manage/app-widget/create-recent-product/'),
  createRecommendCampaign: axiosFrame('post', '/manage/app-widget/create-recommend-campaign/'),
  createFixedCampaign: axiosFrame('post', '/manage/app-widget/create-fixed-campaign/'),
}
