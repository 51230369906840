import {
  computed,
} from '@vue/composition-api'
import data from './data'
import actions from './actions'

export default () => {
  const fields = [
    { key: 'idx' },
    { key: 'title', label: 'title' },
    { key: 'step', label: 'step' },
    { key: 'manage_step', label: 'manage_step' },
  ]
  const turnOnSidebar = () => {
    if (data.recommendCampaign.items.value?.length > 0) {
      const idxList = data.recommendCampaign.items.value.map(item => item.idx)
      const textValue = idxList.join('\n')
      data.recommendCampaign.text.value = textValue
    }
    data.recommendCampaign.visible.value = true
  }
  const turnOffSidebar = () => {
    data.recommendCampaign.visible.value = false
  }
  const isValid = computed(() => Boolean(data.recommendCampaign.text.value))
  const getIdxList = () => {
    const textValue = data.recommendCampaign.text.value
    if (textValue == null) throw Error()
    const idxList = textValue.split('\n').filter(idx => Boolean(idx)).map(idx => parseInt(idx, 10))
    return idxList
  }
  const searchCampaignList = async () => {
    const idxList = getIdxList()
    const response = await actions.searchCampaignList({
      idx_list: idxList,
    })
    const campaignList = response.data.data
    return campaignList
  }
  const updateCampaignList = async () => {
    data.recommendCampaign.errorMessage.value = null
    data.recommendCampaign.isSaving.value = true
    try {
      const idxList = getIdxList()
      const campaignList = await searchCampaignList()
      const campaignIdxList = campaignList.map(p => p.idx)
      const notExistIdxList = idxList.filter(idx => !campaignIdxList.includes(idx))
      if (notExistIdxList.length > 0) {
        data.recommendCampaign.errorMessage.value = `${notExistIdxList}가 존재하지 않습니다`
      } else {
        data.recommendCampaign.items.value = campaignList
        turnOffSidebar()
      }
    } catch (e) {
      console.log(e)
    } finally {
      data.recommendCampaign.isSaving.value = false
    }
  }
  return {
    fields,
    turnOnSidebar,
    turnOffSidebar,
    updateCampaignList,
    isValid,
    ...data.recommendCampaign,
  }
}
