<template>
  <BCard title="콘텐츠 정보">
    <HomeSimple v-if="type === 'HOME_SIMPLE'" />
    <RecentProduct v-if="type === 'RECENT_PRODUCT'" />
    <RecommendCampaign v-if="type === 'RECOMMEND_CAMPAIGN'" />
    <RecommendCampaign v-if="type === 'FIXED_CAMPAIGN'" />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
} from 'bootstrap-vue'
import HomeSimple from './HomeSimple.vue'
import RecentProduct from './RecentProduct.vue'
import RecommendCampaign from './RecommendCampaign.vue'
import useWidgetInfo from '../../useWidgetInfo'

export default defineComponent({
  components: {
    BCard,
    HomeSimple,
    RecentProduct,
    RecommendCampaign,
  },
  setup() {
    const {
      type,
    } = useWidgetInfo()

    return {
      type,
    }
  },
})
</script>
