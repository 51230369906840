<template>
  <BCard title="위젯 정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="TYPE">
          <TagbySelect
            v-model="type"
            :options="typeOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="STATE">
          <TagbySelect
            v-model="state"
            :options="stateOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="NAME">
          <BFormInput
            v-model="name"
            placeholder="해당 위젯을 분별하기 위해 부여하는 값"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow v-if="type === 'RECENT_PRODUCT'">
      <BCol cols="4">
        <BFormGroup label="START_AT(optonal)">
          <TagbyDatetimeInputV2
            v-model="startAt"
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="END_AT(optonal)">
          <TagbyDatetimeInputV2
            v-model="endAt"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <ExplanationHomeSimple v-if="type === 'HOME_SIMPLE'" />
    <ExplanationRecentProduct v-if="type === 'RECENT_PRODUCT'" />
    <ExplanationRecommendCampaign v-if="type === 'RECOMMEND_CAMPAIGN'" />
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import useWidgetInfo from '../../useWidgetInfo'
import ExplanationHomeSimple from './ExplanationHomeSimple.vue'
import ExplanationRecentProduct from './ExplanationRecentProduct.vue'
import ExplanationRecommendCampaign from './ExplanationRecommendCampaign.vue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyDatetimeInputV2,
    TagbySelect,
    ExplanationHomeSimple,
    ExplanationRecentProduct,
    ExplanationRecommendCampaign,
  },
  setup() {
    const {
      type,
      name,
      state,
      startAt,
      endAt,
      typeOptions,
      stateOptions,
    } = useWidgetInfo()

    type.value = typeOptions[0].value
    state.value = stateOptions[0].value

    return {
      type,
      name,
      state,
      startAt,
      endAt,
      typeOptions,
      stateOptions,
    }
  },
})
</script>
